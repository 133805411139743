import { motion, MotionConfig } from "framer-motion";
import React, { useState } from "react";
import { APP_HEIGHT, APP_WIDTH } from "../constants";
import lotuIcon from "../images/book_icon.svg";
import useWindowDimensions from "../utils/WindowDimensions";

type NavBarProps = {};

export const NavBar = (props: NavBarProps) => {
  const { screenWidth, screenHeight } = useWindowDimensions();

  return (
    <div
      style={{
        width: "100%",
        height: screenWidth > 700 ? "75px" : "50px",
        display: "absolute",
        background: "rgba(255, 255, 255, 0.22)",
        boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
        backdropFilter: "blur(6.8px)",
      }}
    >
      <div style={{ display: "flex", paddingTop: "10px" }}>
        <img
          src={lotuIcon}
          alt=""
          style={{
            marginTop: screenWidth > 700 ? "0" : "-10px",
            marginLeft: "25px",
          }}
        ></img>
        <div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: [0, 1, 1] }}
            transition={{ duration: 3 }}
            style={{
              textAlign: "start",
              fontSize: screenWidth > 700 ? "2em" : "1em",
              fontWeight: "bold",
              color: "#555",
              marginLeft: "25px",
            }}
          >
            Library of the Unknown
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: [0, 0, 1] }}
            transition={{ duration: 3 }}
            style={{
              textAlign: "start",
              fontSize: screenWidth > 700 ? "1em" : ".25em",

              fontWeight: "bold",
              color: "#555",
              verticalAlign: "middle",
              marginLeft: "25px",
              letterSpacing: screenWidth > 700 ? "6.25px" : "4px",
            }}
          >
            {"Your girth is your worth"}
          </motion.div>
        </div>
      </div>
    </div>
  );
};
