import React from "react";
import "./App.css";
import { LibraryHome } from "./Controls/LibraryHome";
import { NavBar } from "./Controls/NavBar";

function App() {
  return (
    <div
      className="App"
      style={{ overflow: "hidden", height: "100vh", background: "#f1f2f3" }}
    >
      <NavBar />
      <LibraryHome></LibraryHome>
    </div>
  );
}

export default App;
