import { url } from "inspector";
import bg from "../images/Rain-50s-1921px.svg";
import { motion } from "framer-motion";
import useWindowDimensions from "../utils/WindowDimensions";

type LibraryHomeProps = {};

export const LibraryHome = (props: LibraryHomeProps) => {
  const { screenWidth, screenHeight } = useWindowDimensions();
  return (
    <div
      style={{
        width: "100%",
        height: "80%",
        display: "absolute",
        background: "url(" + bg + ")",
        backgroundRepeat: "repeat-x",
        color: "#555555",
        paddingTop: screenHeight / 3 + "px",
        marginTop: "-100px",
      }}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: [0, 0, 1] }}
        transition={{ duration: 5 }}
      >
        <motion.div
          animate={{
            scale: [1, 1, 1.1, 1, 1],
            rotate: [0, 1, 0, -3, 0],
          }}
          transition={{ repeat: Infinity, repeatDelay: 1, duration: 5 }}
          style={{
            background: "rgba(255, 255, 255, 0.22)",
            borderRadius: "16px",
            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
            backdropFilter: "blur(6.8px)",
            border: "1px solid rgba(255, 255, 255, 0.33)",
            width: "33%",
            marginLeft: "33%",
            fontSize:
              screenWidth > 1500 ? "5em" : screenWidth > 1000 ? "3em" : "2em",
          }}
        >
          Coming Soon
        </motion.div>
      </motion.div>
    </div>
  );
};
